var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.visible = true}}},[_vm._v(" 添加题目 ")]),_c('a-modal',{attrs:{"title":_vm.editingIndex === -1 ? '添加题目' : '修改题目',"visible":_vm.visible,"footer":null,"width":"1000px"},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":12}},[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("题目内容 ")]),_c('div',{attrs:{"id":"editor"}})])],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"题目类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'itemType',
                {
                  rules: [{ required: true, message: '请选择！' }],
                },
              ]),expression:"[\n                'itemType',\n                {\n                  rules: [{ required: true, message: '请选择！' }],\n                },\n              ]"}]},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"题目分数"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'score',
                {
                  rules: [{ required: true, message: '请填写！' }],
                },
              ]),expression:"[\n                'score',\n                {\n                  rules: [{ required: true, message: '请填写！' }],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"max":9999}})],1)],1),(
            _vm.form.getFieldValue('itemType') &&
            _vm.form.getFieldValue('itemType') !== 'qa' &&
            _vm.form.getFieldValue('itemType') !== 'terms' &&
            _vm.form.getFieldValue('itemType') !== 'blank'
          )?[_c('a-col',{attrs:{"lg":12,"md":12,"sm":12}},[_c('a-form-item',{attrs:{"label":"正确答案"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'answer',
                  {
                    initialValue: _vm.editingObj.answer,

                    rules: [{ required: true, message: '请填写！' }],
                  },
                ]),expression:"[\n                  'answer',\n                  {\n                    initialValue: editingObj.answer,\n\n                    rules: [{ required: true, message: '请填写！' }],\n                  },\n                ]"}],attrs:{"placeholder":"多选题正确答案请用英文逗号分隔"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":12}},[_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("添加选项 ")]),_c('div',{staticClass:"list"},_vm._l((_vm.optionList),function(item,index){return _c('div',{key:index,staticClass:"left item"},[_c('a-space',[_c('a-input',{attrs:{"placeholder":"选项"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),(index === _vm.optionList.length - 1)?_c('a-button',{attrs:{"size":"small","type":"primary","shape":"circle","icon":"plus"},on:{"click":_vm.addOption}}):_vm._e(),(index !== 0)?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"minus"},on:{"click":function($event){return _vm.deleteOption(index)}}}):_vm._e()],1)],1)}),0)])],1)]:_vm._e()],2),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("保存")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }