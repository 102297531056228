<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="考试名">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [{ required: true, message: '请输入！' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="归属目录">
              <a-cascader
                :changeOnSelect="true"
                placeholder
                v-decorator="[
                  'type',
                  {
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
                :options="useableTree"
                :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="有效期">
              <a-range-picker
                style="width: 100%"
                :placeholder="[]"
                :show-time="{
                  hideDisabledOptions: true,
                }"
                format="YYYY-MM-DD HH:mm:ss"
                v-decorator="[
                  'time',
                  {
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="考试时长(分钟)">
              <a-input-number
                style="width: 100%"
                :min="1"
                v-decorator="[
                  'duration',
                  {
                    rules: [{ required: true, message: '请输入！' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="题目列表" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <AddSubject
                :list="list"
                @setList="(value) => (list = value)"
                :editingIndex="editingIndex"
                @changeIndex="editingIndex = -1"
              />

              <div class="list">
                <div class="item" v-for="(item, index) in list" :key="index">
                  <div class="tool">
                    <a-space>
                      <a-icon
                        class="icon"
                        style="color: #1890ff"
                        type="edit"
                        @click="editSubject(index)"
                      />
                      <a-icon
                        class="danger icon"
                        type="delete"
                        @click="deleteSubject(item, index)"
                      />
                    </a-space>
                  </div>
                  <div>
                    <span v-html="item.content"></span>
                  </div>
                  <div class="left">
                    <span>类型：</span>
                    <DataDictFinder dictType="exam_question_type" :dictValue="item.itemType" />
                    <a-divider type="vertical" />
                    <span>分数：</span>
                    <span>{{ item.score }}</span>
                    <template v-if="item.answer">
                      <a-divider type="vertical" />
                      <span>正确答案：</span>
                      <span>{{ item.answer }}</span>
                    </template>
                  </div>

                  <div v-if="Array.isArray(item.optionList)">
                    <span>选项列表</span>
                    <ul style="margin-bottom: 0">
                      <li v-for="(option, index) in item.optionList" :key="index">{{ option.value }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button type="primary" html-type="submit" :loading="saveLoading">确认修改</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </a-card>
  </div>
</template>


<script>
import watermark from "@/mixins/watermark";
import AddSubject from "./components/add-subject.vue";
import { fetchDetail, edit } from "@/api/exam";
import moment from "moment";
import study from "@/mixins/study";

export default {
  name: "editExam",
  mixins: [watermark, study],
  components: {
    AddSubject
  },

  data() {
    return {
      id: null,
      form: this.$form.createForm(this),
      list: [],
      editingIndex: -1,
      saveLoading: false,

      deletedList: [] // 暂存之前有，但删除的题目
    };
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;
      this.form.resetFields();
      this.list = [];
      this.getDetail();
    }
  },

  methods: {
    getDetail() {
      fetchDetail({
        id: this.id
      }).then(res => {
        let type = [res.type];
        this.findFather(type, res.type);

        this.form.setFieldsValue({
          name: res.name,
          type: type,
          time: [moment(res.startTime), moment(res.endTime)],
          duration: res.duration
        });
        if (Array.isArray(res.examItemList)) {
          this.list = res.examItemList.map(item => {
            return {
              ...item,
              optionList: item.examItemOptionVoList
            };
          });
        }
      });
    },

    editSubject(index) {
      this.editingIndex = index;
    },
    deleteSubject(item, index) {
      if (item.id) {
        this.deletedList.push(item);
      }
      this.list.splice(index, 1);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);

          if (!this.list.length) {
            this.$message.error("请填写题目");
            return;
          }

          console.log("values", values);

          let startTime, endTime;
          if (Array.isArray(values.time) && values.time.length === 2) {
            startTime = values.time[0].format("YYYY-MM-DD HH:mm:ss");
            endTime = values.time[1].format("YYYY-MM-DD HH:mm:ss");
          }

          this.saveLoading = true;
          edit({
            id: this.id,
            ...values,
            type: values.type[values.type.length - 1],
            time: undefined,
            startTime,
            endTime,

            examItemUpdateReqList: [
              ...this.list.map(item => {
                return {
                  ...item,
                  itemOptionUpdateReqList: item.optionList,
                  optionList: undefined,
                  examItemOptionVoList: undefined
                };
              }),
              ...this.deletedList.map(item => {
                return {
                  ...item,
                  delFlag: 1,
                  itemOptionUpdateReqList: item.optionList,
                  optionList: undefined,
                  examItemOptionVoList: undefined
                };
              })
            ]
          })
            .then(() => {
              setTimeout(() => {
                this.saveLoading = false;
                this.$close(this.$route.path);
              }, 1000);
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.list {
  .item {
    line-height: 2em;
    border: 1px solid #e0e0e0;
    padding: 12px;
    margin-top: 12px;
    position: relative;
    .title {
      font-size: 14px;
      font-weight: bold;
    }
    .subtitle {
      font-weight: bold;
    }
  }
}

.tool {
  position: absolute;
  top: 12px;
  right: 12px;
  .icon {
    cursor: pointer;
  }
}

.center {
  margin-top: 80px;
}
</style>



<style>
p {
  margin-bottom: 4px;
}
table,
th,
td {
  border: 1px solid #e0e0e0;
}
th,
td {
  padding: 8px;
}
</style>