<template>
  <div>
    <a-button type="primary" @click="visible = true"> 添加题目 </a-button>
    <a-modal
      :title="editingIndex === -1 ? '添加题目' : '修改题目'"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
      width="1000px"
    >
      <a-form :form="form" :colon="false" @submit="handleSubmit">
        <a-row :gutter="12">
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item>
              <span class="required" slot="label">题目内容 </span>
              <div id="editor"></div>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="题目类型">
              <a-select
                v-decorator="[
                  'itemType',
                  {
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in typeList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="题目分数">
              <a-input-number
                :max="9999"
                v-decorator="[
                  'score',
                  {
                    rules: [{ required: true, message: '请填写！' }],
                  },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <template
            v-if="
              form.getFieldValue('itemType') &&
              form.getFieldValue('itemType') !== 'qa' &&
              form.getFieldValue('itemType') !== 'terms' &&
              form.getFieldValue('itemType') !== 'blank'
            "
          >
            <a-col :lg="12" :md="12" :sm="12">
              <a-form-item label="正确答案">
                <a-input
                  placeholder="多选题正确答案请用英文逗号分隔"
                  v-decorator="[
                    'answer',
                    {
                      initialValue: editingObj.answer,

                      rules: [{ required: true, message: '请填写！' }],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="12">
              <a-form-item>
                <span class="required" slot="label">添加选项 </span>
                <div class="list">
                  <div
                    class="left item"
                    v-for="(item, index) in optionList"
                    :key="index"
                  >
                    <a-space>
                      <a-input v-model="item.value" placeholder="选项" />

                      <a-button
                        v-if="index === optionList.length - 1"
                        size="small"
                        type="primary"
                        shape="circle"
                        icon="plus"
                        @click="addOption"
                      ></a-button>
                      <a-button
                        v-if="index !== 0"
                        size="small"
                        type="danger"
                        shape="circle"
                        icon="minus"
                        @click="deleteOption(index)"
                      ></a-button>
                    </a-space>
                  </div>
                </div>
              </a-form-item>
            </a-col>
          </template>
        </a-row>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">保存</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import E from "wangeditor";
import { mapGetters } from "vuex";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    editingIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
      optionList: [
        {
          value: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("exam_question_type");
    },

    editingObj() {
      if (this.editingIndex !== -1) {
        return this.list[this.editingIndex];
      } else {
        return {};
      }
    },
  },

  watch: {
    visible() {
      if (this.visible) {
        const that = this;
        this.$nextTick().then(function () {
          that.initEditor();
        });
      } else {
        this.destroyEditor();
      }
    },

    editingIndex() {
      console.log("watch index change", this.editingIndex);
      if (this.editingIndex !== -1) {
        this.visible = true;
      }
    },
  },

  methods: {
    addOption() {
      this.optionList.push({
        value: "",
      });
    },
    deleteOption(index) {
      this.optionList.splice(index, 1);
    },

    initEditor() {
      const editor = new E("#editor");

      editor.config.height = 150;

      editor.config.zIndex = 1;
      editor.config.placeholder = "";
      editor.config.uploadImgServer = "/api/common-service/file/upload";
      editor.config.uploadImgHeaders = {
        token: window.localStorage.getItem("token"),
      };
      editor.config.uploadFileName = "files";
      editor.config.uploadImgHooks = {
        customInsert: function (insertImgFn, result) {
          if (result && Array.isArray(result.data) && result.data.length > 0) {
            insertImgFn(result.data[0].completePath);
          }
        },
      };
      editor.create();
      this.editor = editor;

      if (this.editingIndex !== -1) {
        const obj = this.list[this.editingIndex];
        editor.txt.html(obj.content);

        if (obj.optionList?.length > 0) {
          this.optionList = [...obj.optionList];
        }

        this.form.setFieldsValue({
          itemType: obj.itemType,
          score: obj.score,
        });
      }
    },

    destroyEditor() {
      this.editor?.destroy();
    },

    cancel() {
      this.form.resetFields();
      this.optionList = [
        {
          value: "",
        },
      ];
      this.$emit("changeIndex");
      this.visible = false;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);

          let content = this.editor.txt.text();

          if (values.itemType === "qa" || values.itemType === "blank") {
            content = this.editor.txt.html();
          }

          if (!content) {
            this.$message.error("请填写题目内容！");
            return;
          }

          let optionList;
          if (
            values.itemType !== "qa" &&
            values.itemType !== "terms" &&
            values.itemType !== "blank"
          ) {
            if (!this.optionList.length) {
              this.$message.error("请填写选项！");
              return;
            }
            let flag = false;
            this.optionList.forEach((item) => {
              if (!item.value) {
                flag = true;
              }
            });
            if (flag) {
              this.$message.error("请填写选项！");
              return;
            }
            optionList = this.optionList;
          }

          if (this.editingIndex !== -1) {
            const newList = [...this.list];
            newList.splice(this.editingIndex, 1, {
              ...this.editingObj,
              ...values,
              content,
              optionList,
            });
            this.$emit("setList", newList);
          } else {
            this.$emit("setList", [
              ...this.list,
              {
                ...values,
                content,
                optionList,
              },
            ]);
          }

          this.cancel();
        }
      });
    },
  },
};
</script>